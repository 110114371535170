//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import LazyHydrate from 'vue-lazy-hydration'
import { HAS_TOKEN } from '~/store/auth/types.auth'
import MainBannerSlider from '~/components/pages/Main/MainBannerSlider.vue'
import CompareListsProducts from '~/components/pages/Main/CompareListsProducts.vue'
import SkeletonWrapper from '~/components/skeleton/SkeletonWrapper'
import RectSkeleton from '~/components/skeleton/rect'
import SkeletonProductCard from '~/components/skeleton/SkeletonProductCard'

export default {
  components: {
    CompareListsProducts,
    LazyHydrate,
    MainBannerSlider,
    SkeletonProductCard,
    SkeletonWrapper,
    RectSkeleton,
    MainCategories: () => import('~/components/pages/Main/MainCategories.vue'),
    MainProducts: () => import('~/components/pages/Main/MainProducts.vue'),
    MainRooms: () => import('~/components/pages/Main/MainRooms.vue'),
  },
  data() {
    return {
      sections: [],
      mainCategoriesLoading: true,
    }
  },
  computed: {
    ...mapState({
      products: state => state.compares.items,
    }),
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
  },
  mounted() {
    this.fetchSections()
  },
  methods: {
    fetchSections() {
      return this.$api.main.sectionsList().then(({ data }) => {
        this.sections = data.sections
      })
    },
    onMainCategoryImageLoaded() {
      this.mainCategoriesLoading = false
    },
  },
}
